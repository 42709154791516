import {jwtDecode} from 'jwt-decode'; // Importa jwtDecode

export const checkAuth = () => {
  const token = localStorage.getItem('token');

  if (token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      // Verifica se o token está expirado
      if (decoded.exp < currentTime) {
        localStorage.removeItem('token'); // Remove token expirado
        return false; // Não autenticado
      } else {
        return true; // Autenticado
      }
    } catch (error) {
      localStorage.removeItem('token'); // Remove token inválido
      return false; // Não autenticado
    }
  } else {
    return false; // Não autenticado
  }
};
