import React, { useState,useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Layout from "../components/layout";
import topbanner from '../assets/topbanner.png';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css'; // Estilos do Toastify


type UserType = {
  _id: string;
  name: string;
  email: string;
  bio: string;
  avatar: string;
  cep: string;
  rua: string;
  bairro: string;
  cidade: string;
  uf: string;
  skills:string[];
};

const Encontre = () => {
  const [searchTerm, setSearchTerm] = useState(""); // Para capturar o valor do input
  const [filters, setFilters] = useState<string[]>([]); // Para armazenar múltiplas habilidades filtradas
  const [selectedUser, setSelectedUser] = useState<UserType | null>(null);
  const navigate = useNavigate(); // Inicializando o hook useNavigate
  const [users, setUsers] = useState<UserType[]>([]); // O estado 'users' será um array de User
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const token = localStorage.getItem('token'); // Obtém o token do localStorage

  // Função para adicionar uma habilidade ao pressionar "Enter"
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchTerm.trim() && !filters.includes(searchTerm)) {
      setFilters([...filters, searchTerm.trim()]);
      setSearchTerm("");
    }
  };

  function abreviarNome(nomeCompleto:String) {
    const nomes = nomeCompleto.split(" ");
    const primeiroNome = nomes[0];
    const sobrenomesAbreviados = nomes.slice(1).map(nome => nome.charAt(0).toUpperCase()).join(" ");
    
    return `${primeiroNome} ${sobrenomesAbreviados}`;
  }

  // Função para remover um filtro
  const removeFilter = (skill: string) => {
    setFilters(filters.filter(f => f !== skill));
  };

  // Filtrando usuários que possuem todas as habilidades filtradas
  const filteredUsers = users.filter(user =>
    filters.every(filter =>
      user.skills.some(skill => skill.toLowerCase().includes(filter.toLowerCase()))
    )
  );


  const EnviarMensagem = async (idUser:String) => {
   
   
   
      try {
        debugger
        const response = await fetch(process.env.REACT_APP_API_URL+'api/chat/conversations', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ idparticipante:idUser })
        });

        if (!response.ok) {
          throw new Error('Erro ao enviar mensagem!');
        }
        else{
        window.location.href = "/chat";
        }

       
      } catch (err) {
        console.error(err);
      }
    }
 

   // Função para buscar usuários
   const fetchUsers = async () => {
    try {
        const response = await fetch(process.env.REACT_APP_API_URL+'api/users', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
        if (!response.ok) {
            throw new Error('Erro ao buscar dados');
        }
        const data: UserType[] = await response.json();
        setUsers(data);  // Armazenando os usuários no estado
        setLoading(false);  // Desativando o loading após o sucesso
    } catch (err) {
        setError((err as Error).message);
        setLoading(false);  // Desativando o loading em caso de erro
    }
};

// useEffect para carregar os dados assim que o componente for montado
useEffect(() => {
    fetchUsers();
}, []);  // O array vazio faz com que o useEffect rode apenas na montagem

  return (
    <Layout>
      <div>
        {/* Banner com o input sobreposto na parte inferior */}
        <div style={{ position: "relative", width: "100vw", marginLeft: "calc(-50vw + 50%)" }}>
          <img src={topbanner} style={{ maxWidth: "100%" }} alt="Top Banner" />
          
          {/* Input posicionado na metade inferior do banner */}
          <div
  style={{
    position: "absolute",
    bottom: "-40px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    zIndex: 10,
  }}
>
  {/* Usar input-group para incluir o botão dentro do campo de input */}
  <div className="input-group">
    <input
      type="text"
      className="form-control"
      style={{
        height: "60px",
        boxShadow: "1px 7px 18px 0px #9f9f9f88",
        borderRadius: "10px 0 0 10px", // Bordas arredondadas apenas à esquerda
        fontSize: "20px",
      }}
      placeholder="Procure por habilidades..."
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onKeyPress={handleKeyPress} // Pressionar Enter para adicionar
      aria-label="Procure por habilidades"
    />
    {/* Botão "Filtrar" dentro do input */}
    <button
      className="btn btn-primary"
      style={{
        height: "60px",
        borderRadius: "0 10px 10px 0", // Bordas arredondadas apenas à direita
        fontSize: "20px",
        backgroundColor:"#6d39d3",
        border:"none"
      }}
      onClick={() => {
        if (searchTerm.trim() && !filters.includes(searchTerm)) {
          setFilters([...filters, searchTerm.trim()]);
          setSearchTerm("");
        }
      }}
    >
      Filtrar
    </button>
  </div>
</div>

        </div>

        {/* Espaço extra para evitar sobreposição */}
        <div style={{ marginTop: "60px" }}></div>

        {/* Exibir habilidades filtradas como badges */}
        <div className="container">
          <div className="mb-3 d-flex flex-wrap">
            {filters.map((filter, index) => (
              <span
                key={index}
                className="badge bg-primary text-white me-2 mb-2"
                style={{
                  fontSize: "0.875rem",
                  padding: "0.5rem 0.75rem",
                  borderRadius: "20px",
                }}
              >
                {filter}{" "}
                <button
                  type="button"
                  className="btn-close btn-close-white ms-2"
                  aria-label="Close"
                  onClick={() => removeFilter(filter)} // Remover o filtro ao clicar
                  style={{ fontSize: "0.7rem", verticalAlign: "middle" }}
                ></button>
              </span>
            ))}
          </div>

          {/* Exibir usuários filtrados */}
          <div className="row">
            {filteredUsers.map(user => (
              <div className="col-md-4 mb-4" key={user._id}>
                <div className="card p-3 shadow-sm" style={{ borderRadius: "12px", minHeight: "250px" }}>
                  <div className="card-header d-flex align-items-center bg-white border-0 p-0 mb-3">
                    <img
                      src={user.avatar}
                      alt={user.name}
                      className="rounded-circle me-3"
                      style={{ width: "40px", height: "40px", objectFit: "cover" }}
                    />
                    <h5 className="mb-0" style={{ fontWeight: "bold" }}>{abreviarNome(user.name)}</h5>
                  </div>
                  <div className="card-body p-0">
                    <div className="d-flex flex-wrap mb-2">
                      {user.skills.map(skill => (
                        <span
                          key={skill}
                          className="badge bg-light text-dark border me-2 mb-2"
                          style={{
                            fontSize: "0.875rem",
                            padding: "0.5rem 0.75rem",
                            borderRadius: "20px",
                            fontWeight: 500,
                            border: "1px solid #e5e5e5",
                          }}
                        >
                          {skill}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="card-footer bg-white border-0 p-0 col-12">
                    <button
                      type="button"
                      className="btn btn-dark w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#userModal"
                      onClick={() => setSelectedUser(user)}
                      style={{
                        borderRadius: "12px",
                        padding: "0.75rem 1rem",
                        fontWeight: 600,
                        fontSize: "1rem",
                        backgroundColor: "#6d39d3",
                        border:"none"
                       
                      }}
                    >
                      Ver Perfil
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Modal para exibir informações do usuário selecionado */}
          <div className="modal fade" id="userModal" aria-labelledby="userModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="userModalLabel">{selectedUser?.name}</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-center">
                  {selectedUser && (
                    <>
                      <img
                        src={selectedUser.avatar}
                        alt={selectedUser.name}
                        className="rounded-circle mb-3"
                        style={{ width: "80px", height: "80px" }}
                      />
                      <div className="d-flex flex-wrap justify-content-center mb-3">
                        {selectedUser.skills.map(skill => (
                          <span className="badge bg-secondary me-1 mb-1" key={skill}>
                            {skill}
                          </span>
                        ))}
                      </div>
                      <p>{selectedUser.bio}</p>
                      <button type="button" className="btn btn-success" onClick={() => EnviarMensagem(selectedUser._id)}>Enviar Mensagem</button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Encontre;
