import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoeus from '../../assets/logo-EUS.png';
import imgbannerTop from '../../assets/rostos2.png';
import step1 from '../../assets/1.png';
import step2 from '../../assets/2.png';
import step3 from '../../assets/3.png';
import step4 from '../../assets/5.png';
import step5 from '../../assets/4.png';
import ilustration from '../../assets/ilustrationbanner.png';
import ilustrationtopbanner from '../../assets/ilustrationtopbanner.png';
import { useNavigate } from 'react-router-dom';
import './landing.css';

function Landing() {
    const navigate = useNavigate();

    return (
        <div>
            {/* Menu Fixo */}
            <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top" style={{ height: '85px', fontWeight: '700' }}>
                <div className="container d-flex align-items-center" style={{ height: '100%' }}>
                    <a className="navbar-brand" href="https://encontreumsocio.com.br/encontre">
                        <img src={logoeus} height="47" alt="Logo encontre um sócio" loading="lazy" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto d-flex align-items-center">
                            <li className="nav-item">
                                <button
                                    className="btn"
                                    style={{ backgroundColor: '#ffbf43', width: '232px', height: '57px', borderRadius: '34px', fontWeight: 'bolder' }}
                                    onClick={() => navigate('/login')}
                                >
                                    Faça parte/Login
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            {/* Margem para compensar o menu fixo */}
            <div style={{ marginTop: '80px' }}></div>

            {/* Primeira Parte - Duas colunas (Texto à esquerda, imagem à direita) cor branca */}
            <section className="py-5 bg-white">

                <div className="row align-items-center responsiverow">
                    {/* Texto à esquerda */}
                    <div className="col-md-6 col-sm-12 text-center text-md-start">
                        <div className='divTopLanding'>
                            <h1 style={{ fontSize: '40px' }}><b>Seu futuro sócio está aqui</b></h1>
                            <p>Encontre o sócio perfeito para transformar sua ideia em realidade e dar vida ao seu projeto</p>
                            <button
                                className="btn"
                                style={{ backgroundColor: '#ffbf43', width: '232px', height: '57px', borderRadius: '34px', fontWeight: 'bolder' }}
                                onClick={() => navigate('/login')}
                            >
                                Comece agora
                            </button>
                        </div>
                    </div>

                    {/* Imagem à direita */}
                    <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                        <img
                            src={imgbannerTop}
                            alt="Imagem 1"
                            className="img-fluid imgtoplanding"
                            style={{ maxWidth: '100%', height: 'auto', marginRight: 0 }}
                        />
                    </div>

                </div>

            </section>

            {/* Segunda Parte - Ilustração no topo */}
            <section className="py-5" style={{ backgroundColor: '#eaf7ff' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <img src={ilustrationtopbanner} alt="Ilustração" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            {/* Terceira Parte - Veja como funciona */}
            <section className="py-5 bg-white text-center">
                <div className="container">
                    <h1><b>Veja como funciona</b></h1>
                    <p style={{ fontWeight: '300' }}>Direto ao ponto, sem complicações</p>
                    <div className="row">
                        {/* Seções de texto e imagem, alternadas */}
                        <div className="col-md-6 col-sm-12 textstep" style={{ padding: '70px', textAlign: 'left' }}>
                            <h1 style={{ fontSize: '24px' }}><b>Todos os possíveis sócios</b></h1>
                            <p style={{ fontWeight: '300' }}>Veja a lista completa de possíveis sócios para seu negócio, sem enrolação, estão todos lá!</p>
                        </div>
                        <div className="col-md-6 col-sm-12 " style={{ padding: '20px' }}>
                            <img src={step1} alt="Passo 1" className="img-fluid" style={{ borderRadius: '20px' }} />
                        </div>

                        <div className="row reverse-order ">
                            <div className="col-md-6 col-sm-12" style={{ padding: '20px' }}>
                                <img src={step2} alt="Passo 2" className="img-fluid" style={{ borderRadius: '20px' }} />
                            </div>
                            <div className="col-md-6 col-sm-12 textstep" style={{ padding: '70px', textAlign: 'left' }}>
                                <h1 style={{ fontSize: '24px' }}><b>Filtre pelas habilidades que precisa</b></h1>
                                <p style={{ fontWeight: '300' }}>Filtre pelas habilidades que você procura em um sócio ideal, você pode filtrar por uma ou por um conjunto de habilidades</p>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12 textstep" style={{ padding: '70px', textAlign: 'left' }}>
                            <h1 style={{ fontSize: '24px' }}><b>Mini Perfil</b></h1>
                            <p style={{ fontWeight: '300' }}>Veja o mini perfil do seu futuro parceiro de negócios e se gostar do que viu, envie uma mensagem!</p>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{ padding: '20px' }}>
                            <img src={step3} alt="Passo 3" className="img-fluid" style={{ borderRadius: '20px' }} />
                        </div>
                        <div className="row reverse-order">
                            <div className="col-md-6 col-sm-12" style={{ padding: '20px' }}>
                                <img src={step4} alt="Passo 4" className="img-fluid" style={{ borderRadius: '20px' }} />
                            </div>
                            <div className="col-md-6 col-sm-12 textstep" style={{padding: '70px', textAlign: 'left' }}>
                                <h1 style={{ fontSize: '24px' }}><b>Monitore novos usuários</b></h1>
                                <p style={{ fontWeight: '300' }}>Monitore novos usuários cadastrados com as habilidades que você deseja. Nós avisaremos você todos os dias caso tenhamos novidades.</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 textstep" style={{ padding: '70px', textAlign: 'left' }}>
                            <h1 style={{ fontSize: '24px' }}><b>Conversa segura por chat</b></h1>
                            <p style={{ fontWeight: '300' }}>Fale de maneira segura com seus possíveis sócios pelo nosso chat. Se a conversa evoluir, fique à vontade para trocar contatos pessoais.</p>
                        </div>
                        <div className="col-md-6 col-sm-12" style={{ padding: '20px' }}>
                            <img src={step5} alt="Passo 5" className="img-fluid" style={{ borderRadius: '20px' }} />
                        </div>
                    </div>
                </div>
            </section>

            {/* Quinta Parte - FAQ */}
            <section className="py-5">
                <div className="container">
                    <div className="row align-items-center">
                    
                        <div className="col-md-8 col-sm-12 mx-auto">
                            <div className="text-center" style={{ fontSize: '30px' }}><b>FAQ</b></div>
                            <br />
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <strong>O que é o "Encontre um Sócio"?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            O "Encontre um Sócio" é uma plataforma que conecta pessoas interessadas em encontrar parceiros para projetos, negócios ou startups, com base em habilidades e interesses em comum. Através de um sistema de "match", você pode encontrar sócios que complementem suas habilidades e objetivos.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <strong>Como funciona o sistema de "match"?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Nosso sistema de "match" utiliza as informações fornecidas por você durante o cadastro, como habilidades, interesses e áreas de atuação, para sugerir potenciais sócios. Além disso, você pode utilizar filtros de busca para encontrar parceiros com características específicas.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            <strong>Como posso entrar em contato com outro usuário?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            O contato entre os usuários é feito, primeiramente, através do nosso chat interno. Se a conversa evoluir, fique à vontade para trocar contatos pessoais.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            <strong>Existe um custo para usar a plataforma?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Não, o uso da plataforma é grátis.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            <strong>Como posso monitorar novas oportunidades e parceiros?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Você pode configurar alertas para monitorar as habilidades que deseja em um sócio. Quando novos usuários que possuem essas habilidades se cadastrarem, você será notificado por e-mail.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                            <strong>A plataforma é segura?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Sim! Levamos a privacidade dos nossos usuários muito a sério. Apenas informações relevantes para o "match" são exibidas publicamente, e o contato direto entre os usuários só ocorre mediante o consentimento de ambas as partes.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                            <strong>Como posso redefinir minha senha?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Caso tenha esquecido sua senha, clique na opção "Esqueci minha senha" na página de login. Você receberá um e-mail com um link para criar uma nova senha.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingEight">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                            <strong>Posso editar meu perfil após o cadastro?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Sim, você pode editar suas informações de perfil a qualquer momento. Basta acessar a área de configurações do seu perfil e ajustar suas habilidades, interesses e informações pessoais.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTen">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                            <strong>A plataforma é acessível via dispositivos móveis?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Sim, o "Encontre um Sócio" é totalmente responsivo e pode ser acessado por dispositivos móveis e desktops, oferecendo uma experiência otimizada em qualquer plataforma.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingEleven">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                            <strong>Quais habilidades posso adicionar ao meu perfil?</strong>
                                        </button>
                                    </h2>
                                    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Você pode adicionar habilidades em diversas áreas, como tecnologia, design, negócios, entre outras. Quanto mais detalhado for o seu perfil, melhores serão as sugestões de "match".
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                      
                    </div>
                </div>
            </section>
            <footer className="py-4 bg-dark text-white">
                <div className="container text-center">
                    <p>&copy; 2024 Encontre Um Sócio. Todos os direitos reservados.</p>
                </div>
            </footer>
        </div>
    );
}

export default Landing;
