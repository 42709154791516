import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logoeus from '../assets/logo-EUS.png'
import { ToastContainer } from 'react-toastify'; // Importando o Toastify
const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const tokenLog = localStorage.getItem("token");
  let avatar = localStorage.getItem("avatar")|| '';
const logout = () =>
{
localStorage.removeItem("token");
window.location.href = "/login";
}



  return (
    <>
     <ToastContainer />
     <nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container-fluid">
  <a className="navbar-brand" href="https://encontreumsocio.com.br/encontre">
                <img
                  src={logoeus}
                  height="47"
                  alt="Logo encontre um sócio"
                  loading="lazy"
                />
              </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <Link className="nav-link" to="/encontre">Encontre</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/monitor">Monitorar</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to="/chat">Chat</Link>
        </li>
        
      </ul>
      <div className="dropdown">
                <a
                  className="dropdown-toggle d-flex align-items-center hidden-arrow"
                  href="#"
                  id="navbarDropdownMenuAvatar"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={avatar}
                    className="rounded-circle"
                    height="25"
                    alt="Avatar"
                    loading="lazy"
                    style={{height:"46px",width:"46px"}}
                  />
                </a>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                  <li><Link className="dropdown-item" to="/profile">Meu Perfil</Link></li>
                  <li><Link className="dropdown-item" to="#" onClick={() => logout()}>Sair</Link></li>
                </ul>
              </div>
    </div>
  </div>
</nav>
      {/* <nav className="navbar navbar-expand-lg" style={{ height: "80px", backgroundColor:"#fff" }}>
        <div className="container-fluid">
          <div className="row w-100">
          
            <div className="col-2"></div>

          
            <div className="col-2 d-flex justify-content-center align-items-center">
              <a className="navbar-brand" href="#">
                <img
                  src={logoeus}
                  height="47"
                  alt="Logo"
                  loading="lazy"
                />
              </a>
            </div>

          
            {tokenLog != null || tokenLog != undefined ? 
            <div className="col-4 col-sm-3">
              <div className="collapse navbar-collapse" style={{marginTop:"10px"}}>
                <ul className="navbar-nav justify-content-center w-100 mb-2 mb-lg-0">
                
                  <li className="nav-item">
                    <Link className="nav-link" to="/encontre">Encontre</Link>
                  </li>
                  
                  <li className="nav-item">
                    <Link className="nav-link" to="/monitor">Monitorar</Link>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/chat">Chat</Link>
                  </li>
          
                </ul>
              </div>
            </div>
   : null}
           
            {tokenLog != null || tokenLog != undefined ? 
            <div className="col-2 d-flex justify-content-end align-items-center">
             
                        
              <div className="dropdown">
                <a
                  className="dropdown-toggle d-flex align-items-center hidden-arrow"
                  href="#"
                  id="navbarDropdownMenuAvatar"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={avatar}
                    className="rounded-circle"
                    height="25"
                    alt="Avatar"
                    loading="lazy"
                    style={{height:"46px",width:"46px"}}
                  />
                </a>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdownMenuAvatar">
                  <li><Link className="dropdown-item" to="/profile">Meu Perfil</Link></li>
                  <li><Link className="dropdown-item" to="#" onClick={() => logout()}>Sair</Link></li>
                </ul>
              </div>
            </div>
            :
            null
            }
          </div>
        </div>
      </nav> */}

      <div className="container">
        {children}
      </div>
    </>
  );
};

export default Layout;
