import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Encontre from './pages/encontre'
import Monitor from './pages/monitor'
import UserRegistration from './pages/profile'
import LoginPage from './pages/login/login';
import ProtectedRoute from './components/protectedroute';
import Chat from './pages/chat/chat';
import RecuperaPassfrom from './pages/login/recuperapass';
import Landing from './pages/landing/landing';
const App = () => {
  return (
    <Router>
      <Routes>
     
      <Route
          path="/login"
          element={
            <LoginPage/>
          }
        />
        <Route
          path="/"
          element={
            <Landing/>
          }
        />
        <Route
          path="/recupera-password"
          element={
            <RecuperaPassfrom/>
          }
        />
       <Route 
          path="/encontre" 
          element={
            <ProtectedRoute>
              <Encontre /> {/* Esta rota estará protegida */}
            </ProtectedRoute>
          } 
        />

<Route 
          path="/chat" 
          element={
            <ProtectedRoute>
              <Chat /> {/* Esta rota estará protegida */}
            </ProtectedRoute>
          } 
        />

      <Route
          path="/profile"
          element={
            <ProtectedRoute>
            <UserRegistration/>
            </ProtectedRoute>
          }
        />

       
         <Route
          path="/monitor"
          element={
            <ProtectedRoute>
            <Monitor/>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
