import React, { useState, useEffect } from "react";
import './login.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Importando o Toastify
import 'react-toastify/dist/ReactToastify.css'; // Estilos do Toastify
import logoeus from '../../assets/logo-EUS.png'
import { ToastContainer } from 'react-toastify'; // Importando o Toastify
import { verificarSenha } from '../../utils/loginutils';
import { postRequest } from '../../utils/api';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate(); // Inicializando o hook useNavigate
  const [activeTab, setActiveTab] = useState('login'); // Gerencia a aba ativa


  const handleSubmitRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Verificar se as senhas coincidem
    if (password !== confirmPassword) {
      toast.warning("As senhas devem ser iguais");
      return;
    }

    if (!verificarSenha(password)) {
      toast.warning("A senha deve conter pelo menos 6 caracteres, um número e uma letra");
    }

    // Construir objeto para o registro
    const newUser = {
      email,
      password,
    };

    try {
      const data = await postRequest('api/users/register',newUser);
      if(data){
      toast.success(data.message);
      }
      setTimeout(() => {
              window.location.href ="/login"
             }, 2000);
    } catch (error) {
      toast.error("Erro ao cadastrar usuário, tente novamente!");
    }

  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'api/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        debugger
        const data = await response.json();
        localStorage.setItem('token', data.token); // Armazena o token no localStorage
        localStorage.setItem('userID', data.userID); // Armazena o userID no localStorage
        localStorage.setItem('avatar', data.avatar);
        navigate('/encontre'); // Redireciona o usuário após o login
      } else {
        debugger
        const errorData = await response.json();
        toast.error('Usuário ou senha incorretos, verifique suas credenciais');
      }
    } catch (err) {
      console.error(err);
    
    }
  };

  const RecuperaSenha = async () => {

    try {
      if (!email) {
        toast.warning("Preencha o e-mail");
        return false;
      }
      const response = await fetch(process.env.REACT_APP_API_URL + 'api/users/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success('Um e-mail para recuperação foi enviado para ' + email);

      } else {

        const errorData = await response.json();
        toast.error('Algo deu errado, tente novamente');
      }
    } catch (err) {
      console.error(err);
    
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token") != null || localStorage.getItem("token") != undefined) {
      window.location.href = '/encontre';
    }
  }, []);


  return (
    <>
      <ToastContainer />
      <div className="container-login">
        <div>
          <img
            src={logoeus}
            style={{
              width: "200px"
            }}
            alt="Logo encontre um sócio"
            loading="lazy"
          />
        </div>
        <div className="form-wrapper">

          <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 'login' ? 'active' : ''}`}
                onClick={() => setActiveTab('login')}
              >
                Login
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeTab === 'register' ? 'active' : ''}`}
                onClick={() => setActiveTab('register')}
              >
                Cadastro
              </button>
            </li>
          </ul>

          <div className="tab-content" style={{ marginTop: "40px" }}>
            {activeTab === 'login' && (
              <div className="tab-pane fade show active" id="pills-login" role="tabpanel" aria-labelledby="tab-login">
                <form onSubmit={handleSubmit}>
                  <div data-mdb-input-init className="form-outline mb-4" >
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="loginName" className="form-control" placeholder=" " required />
                    <label className="form-label" htmlFor="loginName">Email</label>
                  </div>

                  <div data-mdb-input-init className="form-outline mb-4">
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} id="loginPassword" className="form-control" placeholder=" " required />
                    <label className="form-label" htmlFor="loginPassword">Senha</label>
                  </div>

                  <div className="row mb-4">
                    <div className="col-md-6 d-flex justify-content-center">
                      <div className="form-check mb-3 mb-md-0">
                        <input className="form-check-input" type="checkbox" id="loginCheck" />
                        <label className="form-check-label" htmlFor="loginCheck"> Lembrar minha senha </label>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-center">
                      <a href="#" onClick={() => RecuperaSenha()}>Esqueceu a senha?</a>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary btn-block mb-4 btnDefault" style={{ width: "100%" }}>Entrar</button>


                </form>
              </div>
            )}

            {activeTab === 'register' && (
              <div className="tab-pane fade show active" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
                <form onSubmit={handleSubmitRegister}>




                  <div className="form-outline mb-4">
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} id="registerEmail" className="form-control" placeholder=" " />
                    <label className="form-label" htmlFor="registerEmail">Email</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="password" id="registerPassword" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder=" " />
                    <label className="form-label" htmlFor="registerPassword">Senha</label>
                  </div>

                  <div className="form-outline mb-4">
                    <input type="password" id="registerRepeatPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" placeholder=" " />
                    <label className="form-label" htmlFor="registerRepeatPassword">Repita a senha</label>
                  </div>



                  <button type="submit" className="btn btn-primary btn-block mb-3 btnDefault" style={{ width: "100%" }}>Cadastrar</button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;