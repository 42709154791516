import axios from 'axios';

// Função genérica para requisição GET
export const getRequest = async (endpoint) => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + endpoint);
    return response.data;
  } catch (error) {
    console.error("Erro ao realizar GET:", error);
    throw error;
  }
};

// Função genérica para requisição POST
export const postRequest = async (endpoint, body) => {
  try {
    const response = await axios.post(process.env.REACT_APP_API_URL + endpoint, body);
    return response.data;
  } catch (error) {
    console.error("Erro ao realizar POST:", error);
    throw error;
  }
};

// Função genérica para requisição PUT
export const putRequest = async (endpoint, body) => {
  try {
    const response = await axios.put(process.env.REACT_APP_API_URL + endpoint, body);
    return response.data;
  } catch (error) {
    console.error("Erro ao realizar PUT:", error);
    throw error;
  }
};
