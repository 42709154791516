export function verificarSenha(senha) {
    // Verifica se a senha tem pelo menos 6 dígitos
    if (senha.length < 6) {
      return false;
    }
  
    // Verifica se contém pelo menos uma letra
    const temLetra = /[a-zA-Z]/.test(senha);
    if (!temLetra) {
      return false;
    }
  
    // Verifica se contém pelo menos um número
    const temNumero = /[0-9]/.test(senha);
    if (!temNumero) {
      return false;
    }
  
    // Se passar por todas as verificações
    return true;
  }


  export default verificarSenha;