import { useState, useEffect } from 'react';
import './chat.scss'; // O nome do seu CSS
import '@fortawesome/fontawesome-free/css/all.min.css';
import Layout from '../../components/layout';

const Chat = () => {
    const [conversations, setConversations] = useState([]);
    const [activeChatId, setActiveChatId] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [recipientID, setRecipientID] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const [showMessages, setShowMessages] = useState(true); // Estado para controlar a visibilidade da lista de mensagens
    const [currentRecipient, setCurrentRecipient] = useState(''); // Novo estado para armazenar o nome do destinatário

    useEffect(() => {
        const fetchConversations = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await fetch(process.env.REACT_APP_API_URL + 'api/chat/conversations', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    throw new Error('Erro ao buscar conversas');
                }

                const data = await response.json();
                setConversations(data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchConversations();
        const intervalId = setInterval(fetchConversations, 5000);

        return () => clearInterval(intervalId);
    }, []);

    const handleChatClick = (conversation) => {
        setActiveChatId(conversation._id);
        const otherParticipant = conversation.participants.find(participant => participant.userId._id !== localStorage.getItem("userID"));
        setRecipientID(otherParticipant.userId._id);
        setCurrentRecipient(otherParticipant.userId.name); // Atualiza o nome do destinatário
        if (isMobileView) {
            setShowMessages(false); // Colapsa a lista de mensagens apenas se for mobile
        }
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();

        if (newMessage.trim() === '' || activeChatId === null || isSending) {
            return;
        }

        setIsSending(true);
        const messageObject = {
            content: newMessage,
            sender: localStorage.getItem('userID'),
            recipient: recipientID,
        };

        try {
            const token = localStorage.getItem("token");
            const response = await fetch(process.env.REACT_APP_API_URL + `api/chat/conversations/${activeChatId}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(messageObject)
            });

            if (!response.ok) {
                throw new Error('Erro ao enviar mensagem');
            }

            const newMessageResponse = await response.json();

            setConversations(conversations.map(conversation => {
                if (conversation._id === activeChatId) {
                    return {
                        ...conversation,
                        messages: [...conversation.messages, newMessageResponse],
                    };
                }
                return conversation;
            }));

            setNewMessage('');
        } catch (err) {
            console.error(err);
        } finally {
            setIsSending(false);
        }
    };

    // Atualiza a largura da tela ao redimensionar
    const handleResize = () => {
        setIsMobileView(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Layout>
            <div className="container py-5 px-4">
                <div className="row rounded-lg overflow-hidden shadow">
                    {/* Users box */}
                    <div className={`col-md-5 px-0 ${isMobileView ? 'd-block' : ''}`}>
                        <div className="bg-white">
                            <div
                                className="bg-light px-4 py-2 d-flex justify-content-between align-items-center"
                                onClick={() => isMobileView && setShowMessages(prev => !prev)} // Alterna a visibilidade da lista de mensagens ao clicar apenas na versão mobile
                                style={{ cursor: 'pointer' }}
                            >
                                <p className="h5 mb-0 py-1">{currentRecipient ? `Para: ${currentRecipient}` : 'Mensagens'}</p>
                                {isMobileView && <span>{showMessages ? '▲' : '▼'}</span>} {/* Ícone de colapso/expansão apenas na versão mobile */}
                            </div>

                            {isMobileView ? showMessages && ( // Renderiza a lista de mensagens apenas se showMessages for verdadeiro na versão mobile
                                <div className="messages-box">
                                    <div className="list-group rounded-0">
                                        {conversations.map((conversation) => {
                                            const otherParticipant = conversation.participants.find(participant => participant.userId._id !== localStorage.getItem("userID"));

                                            return (
                                                <a style={{backgroundColor: conversation.messages.some(msg => !msg.isRead && msg.recipient === localStorage.getItem("userID")) ? "#000" : "#fff"}}  
                                                    key={conversation._id}
                                                    href="#"
                                                    onClick={() => handleChatClick(conversation)}
                                                    className={`list-group-item list-group-item-action ${activeChatId === conversation._id ? 'active text-white' : 'list-group-item-light'} rounded-0`}
                                                >
                                                    <div className="d-flex">
                                                        <div className="ms-3">
                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                <h6 className="mb-0">{otherParticipant?.userId?.name || "Desconhecido"}</h6>
                                                                <small className="text-muted" style={{ marginLeft: "5px" }}>
                                                                    {conversation.messages[conversation.messages.length - 1]?.date}
                                                                </small>
                                                            </div>
                                                            <p className="font-italic mb-0 text-small">
                                                                {conversation.messages[conversation.messages.length - 1]?.content.length > 50
                                                                    ? conversation.messages[conversation.messages.length - 1]?.content.slice(0, 50) + '...'
                                                                    : conversation.messages[conversation.messages.length - 1]?.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : ( // Sempre renderiza a lista de mensagens na versão desktop
                                <div className="messages-box">
                                    <div className="list-group rounded-0">
                                        {conversations.map((conversation) => {
                                            const otherParticipant = conversation.participants.find(participant => participant.userId._id !== localStorage.getItem("userID"));

                                            return (
                                                <a
                                                    key={conversation._id}
                                                    href="#"
                                                    onClick={() => handleChatClick(conversation)}
                                                    className={`list-group-item list-group-item-action ${activeChatId === conversation._id ? 'active text-white' : 'list-group-item-light'} rounded-0`}
                                                >
                                                    <div className="d-flex">
                                                        <div className="ms-3">
                                                            <div className="d-flex align-items-center justify-content-between mb-1">
                                                                <h6 className="mb-0">{otherParticipant?.userId?.name || "Desconhecido"}</h6>
                                                                <small className="text-muted" style={{ marginLeft: "5px" }}>
                                                                    {conversation.messages[conversation.messages.length - 1]?.date}
                                                                </small>
                                                            </div>
                                                            <p className="font-italic mb-0 text-small">
                                                                {conversation.messages[conversation.messages.length - 1]?.content.length > 50
                                                                    ? conversation.messages[conversation.messages.length - 1]?.content.slice(0, 50) + '...'
                                                                    : conversation.messages[conversation.messages.length - 1]?.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Chat Box */}
                    <div className="col-md-7 px-0" style={{ visibility: activeChatId ? 'visible' : 'hidden' }}>
                        <div className="px-4 py-5 chat-box bg-white">
                            {conversations
                                .filter((conversation) => conversation._id === activeChatId)
                                .map((conversation) => (
                                    <div key={conversation._id}>
                                        {conversation.messages.map((message, index) => (
                                            <div key={index} className={`d-flex mb-3 ${message.sender === localStorage.getItem("userID") ? 'justify-content-end' : 'justify-content-start'}`}>
                                                <div className={`${message.sender === localStorage.getItem("userID") ? 'bg-primary text-white' : 'bg-light'} rounded py-2 px-3 mb-2`}>
                                                    <p className="text-small mb-0">{message.content}</p>
                                                </div>
                                                <p className="small text-muted" style={{ marginLeft: "5px" }}>{message.date}</p>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                        </div>
                        <form onSubmit={handleSendMessage} className="bg-light px-4 py-3">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control border-0"
                                    placeholder="Digite sua mensagem..."
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    disabled={isSending}
                                />
                                <button className="btn btn-primary" type="submit" disabled={isSending}>Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Chat;
