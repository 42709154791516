import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Layout from "../components/layout";
import topbanner from '../assets/topbanner.png';

const Monitor: React.FC = () => {
  const [monitoredSkills, setMonitoredSkills] = useState<string[]>([]);
  const [newSkill, setNewSkill] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const token = localStorage.getItem('token'); // Obtém o token do localStorage

  const addSkill = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newSkill && !monitoredSkills.includes(newSkill)) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'api/radar', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ skills: [newSkill] })
        });

        if (!response.ok) {
          throw new Error('Erro ao adicionar habilidade');
        }

        setMonitoredSkills([...monitoredSkills, newSkill]);
        setNewSkill("");
      } catch (err) {
        console.error(err);
      }
    }
  };

  const removeSkill = async (skillToRemove: string) => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL+'api/radar', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ skill: skillToRemove })
      });

      if (!response.ok) {
        throw new Error('Erro ao remover habilidade');
      }

      setMonitoredSkills(monitoredSkills.filter((skill) => skill !== skillToRemove));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchRadar = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL+'api/radar', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar dados');
      }

      const data = await response.json();
      setMonitoredSkills(data[0].skills);
    } catch (err) {
      console.error('Erro:', err);
    }
  };

  useEffect(() => {
    fetchRadar();
  }, []);

  return (
    <Layout>
      <div className="container py-4">
        <div style={{ position: "relative", width: "100vw", marginLeft: "calc(-50vw + 50%)" }}>
          <img src={topbanner} style={{ maxWidth: "100%" }} alt="Top Banner" />

          <div
            style={{
              position: "absolute",
              bottom: "-40px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "60%",
              zIndex: 10,
            }}
          >
          </div>
        </div>

        <div className="card mb-4 mt-4" style={{ border: "None" }}>
          <div className="card-header" style={{ border: "None" }}>
            <h5 className="card-title">O que você quer monitorar?</h5>
          </div>
          <div className="card-body" style={{ border: "None" }}>
            <form onSubmit={addSkill} className="d-flex">
              <input
                type="text"
                className="form-control me-2"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                placeholder="Enter a skill"
                aria-label="Enter a skill to monitor"
              />
              <button
                type="submit"
                className="btn btn-primary"
                style={{ backgroundColor: "rgb(109, 57, 211)", border: "None" }}
              >
                Monitorar
              </button>
            </form>
          </div>
        </div>

        <div className="card mb-4" style={{ border: "None" }}>
          <div className="card-header" style={{ border: "None" }}>
            <h5 className="card-title">Você está monitorando</h5>
          </div>
          <div className="card-body" style={{ border: "None" }}>
            <div className="d-flex flex-wrap gap-2">
              {monitoredSkills.length > 0 ? (
                monitoredSkills.map((skill) => (
                  <span key={skill} className="badge bg-secondary me-2">
                    {skill}
                    <button
                      type="button"
                      className="btn-close btn-close-white ms-2"
                      aria-label={`Remove ${skill}`}
                      onClick={() => removeSkill(skill)}
                    ></button>
                  </span>
                ))
              ) : (
                <p className="text-muted">Você não está monitorando nenhuma habilidade no momento.</p>
              )}
            </div>
          </div>
        </div>

        {showToast && (
          <div className="toast-container position-fixed top-0 end-0 p-3" style={{ zIndex: 5 }}>
            <div className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
              <div className="toast-header">
                <strong className="me-auto">New Match!</strong>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowToast(false)}
                ></button>
              </div>
              <div className="toast-body">{toastMessage}</div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Monitor;
