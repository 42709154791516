import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Layout from "../components/layout";
import { toast } from 'react-toastify'; // Importando o Toastify
import 'react-toastify/dist/ReactToastify.css'; // Estilos do Toastify
import "./profile.css"

const UserRegistration: React.FC = () => {
  const [user, setUser] = useState<{
    name: string;
    email: string;
    bio: string;
    avatar: string; // Mantém como string
    cep: string;
    rua: string;
    bairro: string;
    cidade: string;
    uf: string;
  }>({
    name: "",
    email: "",
    bio: "",
    avatar: "", // Inicialmente uma string vazia
    cep: "",
    rua: "",
    bairro: "",
    cidade: "",
    uf: "",
  });
  const [skills, setSkills] = useState<string[]>([]);
  const [newSkill, setNewSkill] = useState("");

  // Função para buscar os dados do usuário do banco de dados
  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token'); // Obtém o token do localStorage
      const response = await fetch(process.env.REACT_APP_API_URL+'api/users/get-user', {
        method: 'GET', // Método da requisição (GET)
        headers: {
            'Content-Type': 'application/json', // Define o tipo de conteúdo
            'Authorization': `Bearer ${token}` // Adiciona o token ao cabeçalho
        }
    });
      if (!response.ok) {
        throw new Error('Erro ao buscar dados do usuário');
      }
      const data = await response.json();
      
      // Preencher os dados do usuário
      setUser({
        name: data.name,
        email: data.email,
        bio: data.bio,
        avatar: data.avatar,
        cep: data.cep,
        rua: data.rua,
        bairro: data.bairro,
        cidade: data.cidade,
        uf: data.uf,
      
      });
      
      // Preencher as habilidades
      setSkills(data.skills);
      
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
    }
  };

  // Função para buscar os dados do endereço via API do ViaCEP
  const fetchAddressByCep = async (cep: string) => {
    if (cep.length === 8) { // Verifica se o CEP tem 8 dígitos
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        const data = await response.json();
        if (!data.erro) {
          setUser(prevUser => ({
            ...prevUser,
            cidade: data.localidade,
            uf: data.uf,
            rua: data.logradouro,
            bairro: data.bairro
          }));
        } else {
          console.error("CEP inválido.");
        }
      } catch (error) {
        console.error("Erro ao buscar o CEP:", error);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setUser(prevUser => ({ ...prevUser, [name]: value }));

    if (name === "cep" && value.length <= 8) {
      // Chama a função para buscar o endereço quando o CEP tiver 8 dígitos
      fetchAddressByCep(value);
    }
  };


  const addSkill = (e: React.FormEvent) => {
    e.preventDefault();
    if (newSkill && !skills.includes(newSkill)) {
      setSkills([...skills, newSkill]);
      setNewSkill("");
    }
  };

  const removeSkill = (skillToRemove: string) => {
    setSkills(skills.filter(skill => skill !== skillToRemove));
  };

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUser(prevUser => ({ ...prevUser, avatar: reader.result as string })); // Converte o arquivo para uma URL de dados
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Criando o objeto para enviar como JSON
    const data = {
        name: user.name,
        email: user.email,
        bio: user.bio,
        cep: user.cep,
        rua: user.rua,
        bairro: user.bairro,
        cidade: user.cidade,
        uf: user.uf,
        avatar: user.avatar, // Base64 string
        skills: skills, // Já é um array, não precisa de stringify
    };

    try {
        const token = localStorage.getItem('token');

        const response = await fetch(process.env.REACT_APP_API_URL + 'api/users/update-user', {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Definindo o tipo como JSON
            },
            body: JSON.stringify(data), // Convertendo o objeto para JSON
        });

        if (response.ok) {
            const result = await response.json();
            toast.success("Atualização feita com sucesso!");
            localStorage.removeItem("avatar");
            localStorage.setItem("avatar",result.avatar)
            debugger
        } else {
            toast.error("Ocorreu um erro, tente novamente.");
        }
    } catch (error) {
        console.error("Erro na requisição:", error);
        toast.error("Erro ao atualizar o perfil.");
    }
};


  useEffect(() => {
    fetchUserData(); // Chama a função para buscar os dados do usuário ao montar o componente
  }, []);

  return (
    <Layout>
      <div className="container my-4">
       
        <form onSubmit={handleSubmit}>
          <div className="card mb-4" style={{border:"none"}}>
           
            <div className="card-body">
              <div className="d-flex align-items-center mb-3">
                <div className="me-3">
                  <img
                    src={user.avatar || 'https://via.placeholder.com/150'}
                    alt={user.name}
                    className="rounded-circle"
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
                <div>
                <label htmlFor="avatar" className="form-label label-profile">Imagem de perfil</label>
                  <input
                    id="avatar"
                    type="file"
                    accept="image/*"
                    onChange={handleAvatarChange}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="name" className="form-label label-profile">Nome Completo</label>
                <input
                  id="name"
                  name="name"
                  value={user.name}
                  onChange={handleInputChange}
                  placeholder="John Doe"
                  required
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label label-profile">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={user.email}
                  onChange={handleInputChange}
                  placeholder="john@example.com"
                  required
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="cep" className="form-label label-profile">CEP</label>
                <input
                  id="cep"
                  name="cep"
                  type="text"
                  value={user.cep}
                  onChange={handleInputChange}
                  placeholder="12345678"
                  className="form-control"
                  maxLength={8} // Limitar o tamanho do input a 8 caracteres
                />
              </div>

              <div className="mb-3">
                <label htmlFor="cidade" className="form-label label-profile">Cidade</label>
                <input
                  id="cidade"
                  name="cidade"
                  type="text"
                  value={user.cidade}
                  onChange={handleInputChange}
                  className="form-control"
                  readOnly // Tornar o campo somente leitura
                />
              </div>

              <div className="mb-3">
                <label htmlFor="uf" className="form-label label-profile">Estado</label>
                <input
                  id="uf"
                  name="uf"
                  type="text"
                  value={user.uf}
                  onChange={handleInputChange}
                  className="form-control"
                  readOnly // Tornar o campo somente leitura
                />
              </div>

              <div className="mb-3">
                <label htmlFor="bio" className="form-label label-profile">Bio</label>
                <textarea
                  id="bio"
                  name="bio"
                  value={user.bio}
                  onChange={handleInputChange}
                  placeholder="Fale um pouco sobre você"
                  rows={4}
                  maxLength={150}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <div className="card mb-4" style={{border:"none"}}>
           
            <div className="card-body">
              <div className="d-flex mb-3">
                <input
                  type="text"
                  value={newSkill}
                  onChange={(e) => setNewSkill(e.target.value)}
                  placeholder="Adicione suas habilidades"
                  className="form-control me-2"
                />
                <button type="button" className="btn " style={{backgroundColor:"rgb(109, 57, 211)",color:"white"}} onClick={addSkill}>
                  +
                </button>
              </div>

              <div className="d-flex flex-wrap">
                {skills.map(skill => (
                  <span key={skill} className="badge bg-secondary me-2 mb-2">
                    {skill}
                    <button
                      type="button"
                      onClick={() => removeSkill(skill)}
                      className="btn-close btn-close-white ms-2"
                      aria-label={`Remove ${skill} skill`}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>

          <button type="submit" style={{backgroundColor:"rgb(109, 57, 211)",width:"150px",float:"right"}} className="btn btn-success">Salvar</button>
        </form>
      </div>
    </Layout>
  );
};

export default UserRegistration;
