import { Navigate } from 'react-router-dom';
import {checkAuth} from '../hooks/useAuth'; // O hook de autenticação que criamos

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = checkAuth();
debugger
  if (!isAuthenticated) {
    // Redireciona para a página de login se não estiver autenticado
    return <Navigate to="/login" replace />;
  }

  return children; // Renderiza a página se o usuário estiver autenticado
};

export default ProtectedRoute;