import React, { useState, useEffect } from "react";
import './login.css';
import { useNavigate,useLocation  } from 'react-router-dom';
import { toast } from 'react-toastify'; // Importando o Toastify
import 'react-toastify/dist/ReactToastify.css'; // Estilos do Toastify
import logoeus from '../../assets/logo-EUS.png'
import { ToastContainer } from 'react-toastify'; // Importando o Toastify
const RecuperaPass = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Inicializando o hook useNavigate
  const location = useLocation();

  const handleSubmitRegister = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
   
    // Verificar se as senhas coincidem
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const utoken = queryParams.get('utoken');

    setError(''); // Limpar erro anterior

    // Construir objeto para o registro
    const updatepass = {
      password,
      utoken,
    };

    try {
      const response = await fetch(process.env.REACT_APP_API_URL + 'api/users/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatepass),
      });

      const data = await response.json();

      if (response.ok) {
        // Exibir notificação de sucesso
        toast.success('Cadastro realizado com sucesso!');
        // Limpar os campos
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        // Redirecionar para a página de login após 2 segundos
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      } else {
        setError(data.message || 'An error occurred during registration.');
      }
    } catch (err) {
      setError('Failed to register. Please try again later.');
    }
  };



  return (
    <>
    <ToastContainer />
    <div className="container-login">
   <div>
        <img
          src={logoeus}
        style={{width:"200px"
        }}
          alt="Logo"
          loading="lazy"
        />
      </div>
      <div className="form-wrapper">

       

    
            <div className="tab-pane fade show active" id="pills-register" role="tabpanel" aria-labelledby="tab-register">
              <form  onSubmit={handleSubmitRegister}>

              


             

                <div className="form-outline mb-4">
                  <input type="password" id="registerPassword" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder=" " />
                  <label className="form-label" htmlFor="registerPassword">Nova senha</label>
                </div>

                <div className="form-outline mb-4">
                  <input type="password" id="registerRepeatPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className="form-control" placeholder=" " />
                  <label className="form-label" htmlFor="registerRepeatPassword">Repita a nova senha</label>
                </div>

            

                <button type="submit" className="btn btn-primary btn-block mb-3 btnDefault"  style={{width:"100%"}}>Alterar senha</button>
              </form>
            </div>
        
        </div>
      </div>
    
    </>
  );
};

export default RecuperaPass;